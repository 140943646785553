import { ModelSelect, BasicSelect } from 'vue-search-select';
import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import { format } from 'date-fns';
import party from '@/app/components/generalLedger/party';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import ModulesList from '@/app/components/admin/responsibility/searchModule';
export default {
  name: 'AdvanceSearch',
  components: {
    ModelSelect,
    BasicSelect,
    DatePicker,
    party,
    ModulesList
  },
  watch: {
    selectedProject: function() {
      if (this.selectedProject.value) {
        this.lovFieldName = appStrings.FMSLOV.FMSPROJECT;
        this.lovId = this.selectedProject.value;
        // this.getOtherLovByProjectId();
      }
    }
  },
  data() {
    return {
      vsetCode: null,
      setTimeout: null,
      showValueSetModal: false,
      isDependent: false,
      parent_value_set_id: null,
      dateRangePicker: {
        opens: true,
        autoApply: true,
        dateRange: null,
        dateFormat: appStrings.DATEFNSFORMAT
      },
      showCreateReceipt: false,
      showPartyModal: false,
      loader: false,
      lovFieldName: '',
      lovId: '',
      keyValue: {
        value: null,
        text: null
      },
      advanceSearchForm: {
        leagalEntity: [
          {
            value: null,
            text: '--select--'
          }
        ],
        receiptAgainst: [
          {
            value: null,
            text: null
          }
        ],
        paymentMode: [
          {
            value: null,
            text: null
          }
        ],
        drawnOn: [
          {
            value: null,
            text: null
          }
        ],
        projectList: [
          {
            value: null,
            text: null
          }
        ],
        sectorList: [
          {
            value: null,
            text: null
          }
        ],
        pocketList: [
          {
            value: null,
            text: null
          }
        ],
        towerList: [
          {
            value: null,
            text: null
          }
        ],
        floorList: [
          {
            value: null,
            text: null
          }
        ],
        unitList: [
          {
            value: null,
            text: null
          }
        ],
        subUnitList: [
          {
            value: null,
            text: null
          }
        ],
        instrumentStatusList: [
          {
            value: null,
            text: null
          }
        ],
        accountNumList: [
          {
            value: null,
            text: null
          }
        ]
      },
      selectedReceiptAgainst: {
        value: null,
        text: null
      },
      selectedLeagalEntity: {
        value: null,
        text: null
      },
      operatingUnit: {
        value: null,
        text: null
      },
      selectedPaymentMode: {
        value: null,
        text: null
      },
      receiptNum: '',
      customer: {
        name: null,
        id: null,
        siteName: null,
        siteId: null
      },
      selectedDrawnOn: {
        value: null,
        text: null
      },
      selectedProject: {
        value: null,
        text: null
      },
      selectedSector: {
        value: null,
        text: null
      },
      selectedPocket: {
        value: null,
        text: null
      },
      selectedTower: {
        value: null,
        text: null
      },
      selectedFloor: {
        value: null,
        text: null
      },
      selectedUnit: {
        value: null,
        text: null
      },
      selectedSubUnit: {
        value: null,
        text: null
      },
      selectedInstrumentStatus: {
        value: null,
        text: null
      },
      selectedAccountNum: {
        value: null,
        text: null
      },
      instrumentStartAmount: null,
      insNo: null,
      instrumentEndAmount: null,
      isAdvanceSearchEnable: false,
      search: '',
      data: [],
      update: false,
      showAlert: false,
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      stickyHeader: true,
      totalRows: null,
      filterOn: [],
      showModal: false,
      editMode: false,
      responseMsg: '',
      isSuccess: false,
      isFailed: false,
      creation_start_date: null,
      creation_end_date: null,
      gl_start_date: null,
      gl_end_date: null,
      value_start_date: null,
      value_end_date: null,
      valueDate: null,
      glDate: null,
      creationDate: null,
      showHideModule: null,
      selectedModule: {
        name: null,
        id: null
      },
      depVsetParam: null,
      showCustomerSiteModal: false,
      gridDataSite: [],
      gridFieldsSite: [
        {
          key: 'site_name'
        },
        {
          key: 'site_type'
        },
        {
          key: 'bill_to_address',
          label: 'Site Address'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'gstn',
          label: 'GSTN'
        }
      ],
    };
  },
  mounted() {
    this.getInstrumentStatus();
    const advFormData = this.$store.state.receivables.advSearchFilters;
    if (advFormData) {
      this.setSearchItems(advFormData);
    } else {
      // this.getInstrumentStatus();
    }
  },
  methods: {
    getInstrumentStatus() {
      this.$store
        .dispatch('receipt/getLOVBySetType', 'RECEIPT_STATUS')
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning
              };
            });
            this.advanceSearchForm.instrumentStatusList = getValueAndText;
          }
        });
    },

    resetSearchForm() {
      this.selectedPaymentMode = {
        value: null,
        text: null
      };
      this.selectedDrawnOn = {
        value: null,
        text: null
      };
      this.selectedProject = {
        value: null,
        text: null
      };
      this.selectedSector = {
        value: null,
        text: null
      };
      this.selectedPocket = {
        value: null,
        text: null
      };
      this.selectedTower = {
        value: null,
        text: null
      };
      this.selectedFloor = {
        value: null,
        text: null
      };
      this.selectedUnit = {
        value: null,
        text: null
      };
      this.selectedSubUnit = {
        value: null,
        text: null
      };
      this.selectedInstrumentStatus = {
        value: null,
        text: null
      };

      this.gl_start_date = null;
      this.gl_end_date = null;
      this.creation_start_date = null;
      this.creation_end_date = null;
      this.value_start_date = null;
      this.value_end_date = null;
      this.valueDate = null;
      this.glDate = null;
      this.creationDate = null;
      this.selectedModule = {
        name: null,
        id: null
      };
      this.customer = {
        name: null,
        id: null
      };
      this.customer = {
        siteId: null,
        siteName: null
      }
      this.selectedReceiptAgainst = {
        value: null,
        text: null
      };
      this.selectedLeagalEntity = {
        value: null,
        text: null
      };
      this.operatingUnit = {
        value: null,
        text: null
      };
      this.receiptNum = '';
      this.instrumentStartAmount = null;
      this.insNo = null;
      this.instrumentEndAmount = null;
      this.$store.dispatch('receivables/saveAdvSearchFilters', null);
      // this.$store.state.receivables.receivables.adv_search = null;
    },
    onSelect(event) {
      this.selectedReceiptAgainst = event;
    },
    receiptSearch() {
      const payload = {
        searchParams: {
          module_id: this.selectedModule.id,
          with_unit: this.selectedReceiptAgainst.value === 'SUBUNIT',
          le_id: this.selectedLeagalEntity.value,
          ou_id: this.operatingUnit.value,
          receipt_mode_vset: this.selectedPaymentMode.value,
          document_num: this.receiptNum,
          customer_id: this.customer.id,
          bank_vset: this.selectedDrawnOn.value,
          prj_id: this.selectedProject.value,
          tower_id: this.selectedTower.value,
          floor_id: this.selectedFloor.value,
          unit_id: this.selectedUnit.value,
          sub_unit_id: this.selectedSubUnit.value,
          value_start_date: this.valueDate
            ? format(this.valueDate[0], appStrings.DATEFNSFORMAT)
            : null,
          value_end_date: this.valueDate
            ? format(this.valueDate[1], appStrings.DATEFNSFORMAT)
            : null,
          gl_start_date: this.glDate
            ? format(this.glDate[0], appStrings.DATEFNSFORMAT)
            : null,
          gl_end_date: this.glDate
            ? format(this.glDate[1], appStrings.DATEFNSFORMAT)
            : null,
          instrument_status_vset: this.selectedInstrumentStatus.value,
          instrument_start_amount: this.instrumentStartAmount,
          instrument_number: this.insNo,
          instrument_end_amount: this.instrumentEndAmount,
          creation_start_date: this.creationDate
            ? format(this.creationDate[0], appStrings.DATEFNSFORMAT)
            : null,
          creation_end_date: this.creationDate
            ? format(this.creationDate[1], appStrings.DATEFNSFORMAT)
            : null,    
          customer_site_id: this.customer.siteId
        }
      };
      this.$emit('advanceSearch', payload);
      this.$store.dispatch('receivables/saveAdvSearchFilters', this);
      // this.$store.state.receivables.receivables.adv_search = this;
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      this.depVsetParam = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.selectedLeagalEntity.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_TOWER) {
        this.parent_value_set_id = this.selectedProject.value;
        this.isDependent = true;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_FLOOR) {
        this.parent_value_set_id = this.selectedTower.value;
        this.isDependent = true;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_UNIT) {
        this.parent_value_set_id = this.selectedFloor.value;
        this.isDependent = true;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_SUB_UNIT) {
        this.parent_value_set_id = this.selectedUnit.value;
        this.isDependent = true;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.parent_value_set_id = this.selectedLeagalEntity.value;
        this.depVsetParam = this.operatingUnit.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT) {
        this.parent_value_set_id = this.selectedLeagalEntity.value;
        this.depVsetParam = this.operatingUnit.value;
      }
      this.setTimeout = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'PAYMENT_MODE') {
        this.selectedPaymentMode.value = item.value_code;
        this.selectedPaymentMode.text = item.value_meaning;
      } else if (this.vsetCode === 'BANK_NAME_VSET') {
        this.selectedDrawnOn.value = item.value_code;
        this.selectedDrawnOn.text = item.value_meaning;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.selectedLeagalEntity.value = item.org_id;
        this.selectedLeagalEntity.text = item.org_name;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.operatingUnit.value = item.org_id;
        this.operatingUnit.text = item.org_name;
      } else if (this.vsetCode === 'FMS_SEARCH_BY') {
        this.selectedReceiptAgainst.value = item.value_code;
        this.selectedReceiptAgainst.text = item.value_meaning;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT) {
        this.selectedProject.text = item.value_code;
        this.selectedProject.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_TOWER) {
        this.selectedTower.text = item.value_code;
        this.selectedTower.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_FLOOR) {
        this.selectedFloor.text = item.value_code;
        this.selectedFloor.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_UNIT) {
        this.selectedUnit.text = item.value_code;
        this.selectedUnit.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_SUB_UNIT) {
        this.selectedSubUnit.text = item.value_code;
        this.selectedSubUnit.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.selectedProject.text = item.value_code;
        this.selectedProject.value = item.value_set_dtl_id;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    hideModuleModal(flag) {
      this.showHideModule = flag;
    },
    selectedModuleData(module) {
      this.selectedModule.name = module.module_name;
      this.selectedModule.id = module.module_id;
      this.showHideModule = false;
      if (this.selectedModule.name === 'Lease Management') {
        this.selectedReceiptAgainst = {
          text: 'With SubUnit',
          value: 'SUBUNIT'
        };
      } else {
        this.selectedReceiptAgainst = {
          value: null,
          text: null
        };
      }
    },
    showHidePartyModal(flag) {
      this.showPartyModal = flag;
      setTimeout(() => {
        this.eventBus.$emit('userType', 'CUST');
      }, 0);
    },
    getSelectedPartyCustomer(item) {
      this.customer.name = item.party_name;
      this.customer.siteName = item.site_name;
      this.customer.siteId = item.customer_site_id;
      this.showPartyModal = false;
      this.getCustomerByPartyId(item.party_id);
    },
    getCustomerByPartyId(partyId) {
      this.$store
        .dispatch('leases/getCustomerByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            this.customer.id = response.data.data.customer_id;
            this.getCustomerSiteByPartyId();
          }
        });
    },
    getCustomerSiteByPartyId() {
      const payload = {
        customerId: this.customer.id
      };
      this.$store
        .dispatch('party/getCustomerSiteById', payload)
        .then(response => {
          this.customer.siteId = response.data.data.customer_site_id;
          this.gridDataSite = response.data.data.customer_site_lists;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideCustomerSiteModal(flag) {
      if (this.customer.name) {
        this.showCustomerSiteModal = flag;
      } else {
        alert('Please Select Customer First');
      }
    },
    selectedSite(item) {
      this.customer.siteName = item.site_name;
      this.customer.siteId = item.customer_site_id;
      this.showCustomerSiteModal = false;
    },
    setSearchItems(item) {
      this.selectedModule = item.selectedModule;
      this.selectedReceiptAgainst = item.selectedReceiptAgainst;
      this.selectedLeagalEntity = item.selectedLeagalEntity;
      this.operatingUnit = item.operatingUnit;
      this.selectedPaymentMode = item.selectedPaymentMode;
      this.receiptNum = item.receiptNum;
      this.customer = item.customer;
      this.selectedDrawnOn = item.selectedDrawnOn;
      this.selectedProject = item.selectedProject;
      this.selectedTower = item.selectedTower;
      this.selectedFloor = item.selectedFloor;
      this.selectedUnit = item.selectedUnit;
      this.selectedSubUnit = item.selectedSubUnit;
      this.selectedInstrumentStatus = item.selectedInstrumentStatus;
      this.instrumentStartAmount = item.instrumentStartAmount;
      this.insNo =  item.insNo;
      this.instrumentEndAmount = item.instrumentEndAmount;
      this.valueDate = item.valueDate;
      this.glDate = item.glDate;
      this.creationDate = item.creationDate;
    },
    chooseValueset(module) {
      if (module === 'FMS') {
        this.openValueSetModal(appStrings.VALUESETTYPE.FMS_PROJECT);
      } else {
        this.openValueSetModal(appStrings.VALUESETTYPE.LEASE_PROJECT_LIST);
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.setTimeout);
  }
};
