import { ModelSelect } from 'vue-search-select';
export default {
  name: 'EditVuewReceipt',
  components: {
    ModelSelect
  },
  props: {},
  watch: {},
  data() {
    return {
      loader: false,
      isEditViewEnable: false,
      receiptDetailObj: '',
      dummyOptions: [
        {
          value: null,
          text: '--select--'
        }
      ],
      selectedDummy: ''
    };
  },
  created() {
    this.eventBus.$on('fmsReceiptId', receiptId => {
      this.loader = true;
      this.receiptDetailObj = '';
      this.$store
        .dispatch('fms/getFMSReceiptById', receiptId)
        .then(response => {
          this.loader = false;
          if (response.data.data) {
            this.isEditViewEnable = true;
            this.receiptDetailObj = response.data.data;
          }
        })
        .catch(error => {
          this.loader = false;
          alert(error.message);
        });
    });
  }
};
