import { ModelSelect, BasicSelect } from 'vue-search-select';
import DatePicker from 'vue2-datepicker';
import EditViewReceipt from './editViewReceipt';
import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import CreateReceipt from './createReceipt/';
import AdvanceSearchModal from './advanceSearchModal';
import ModulesList from '@/app/components/admin/responsibility/searchModule';
export default {
  name: 'Receipt',
  components: {
    DatePicker,
    ModelSelect,
    EditViewReceipt,
    BasicSelect,
    CreateReceipt,
    AdvanceSearchModal,
    ModulesList
  },
  watch: {
    currentPage: function() {
      this.payload.pagination = {
        _page: this.currentPage - 1,
        _limit: this.perPage
      };
      this.getReceiptList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.payload.pagination = {
        _page: this.currentPage - 1,
        _limit: this.perPage
      };
      this.getReceiptList();
    }
  },
  data() {
    return {
      unsubscribe: null,
      searchParameter: null,
      showValueSetModal: false,
      receiptNum: null,
      rcptDateFromTo: [],
      rcptToDate: null,
      rcptFromDate: null,
      projectName: null,
      projectId: null,
      dateRangePicker: {
        opens: true,
        autoApply: true,
        dateRange: null,
        dateFormat: appStrings.DATEFNSFORMAT
      },
      showCreateReceipt: false,
      loader: false,
      lovFieldName: '',
      lovId: '',
      isAdvanceSearchEnable: false,
      search: '',
      data: [],

      update: false,
      showAlert: false,
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,

      stickyHeader: true,
      totalRows: null,
      filterOn: [],
      showModal: false,
      editMode: false,
      responseMsg: '',
      isSuccess: false,
      isFailed: false,
      fields: [
        {
          key: 'receipt_number',
          class: 'text-center'
        },
        {
          key: 'receipt_type_meaning',
          label: 'Receipt Type'
        },
        {
          key: 'module'
        },
        {
          key: 'exchange_type'
        },
        {
          key: 'entity_type'
        },
        {
          key: 'project',
          class: 'text-center'
        },
        {
          key: 'unit_name',
          class: 'text-center'
        },
        {
          key: 'sub_unit_name',
          class: 'text-center'
        },
        {
          key: 'customer_name',
          class: 'text-center'
        },
        {
          key: 'receipt_date',
          class: 'text-center'
        },
        {
          key: 'value_date',
          class: 'text-center'
        },
        {
          key: 'gl_date',
          label: 'GL Date',
          class: 'text-center'
        },
        {
          key: 'process_gl_date',
          label: 'Process GL Date',
          class: 'text-center'
        },
        {
          key: 'payment_mode',
          class: 'text-center'
        },
        {
          Key: 'instrument_bank',
          class: 'text-center'
        },
        {
          key: 'instrument_number',
          class: 'text-center'
        },
        {
          key: 'instrument_date',
          class: 'text-center'
        },
        {
          key: 'instrument_amount'
        },
        {
          key: 'instrument_status',
          class: 'text-center'
        },
        {
          key: 'bank_account_name',
          class: 'text-center'
        },
        {
          key: 'tds_amount',
          label: 'TDS Amount'
        },
        {
          key: 'tds_bank_account_name',
          label: 'TDS Bank Account Name'
        },
        {
          key: 'clearing_date',
          class: 'text-center'
        },
        {
          key: 'clearning_gl_date',
          label: 'Clearing GL Date',
          class: 'text-center'
        },
        {
          key: 'currency',
          class: 'text-center'
        },
        {
          key: 'legal_entity',
          class: 'text-center'
        },
        {
          key: 'sector',
          class: 'text-center'
        },
        {
          key: 'pocket',
          class: 'text-center'
        },
        {
          key: 'tower',
          class: 'text-center'
        },
        {
          key: 'floor',
          class: 'text-center'
        },
        {
          key: 'comments',
          class: 'text-center'
        },
        {
          key: 'reversal_comments',
          class: 'text-center'
        },
        {
          key: 'fms_receipt_hdr_id',
          class: 'd-none'
        }
      ],
      selectedModule: {
        name: null,
        id: null
      },
      showHideModule: false,
      receiptHdrId: null,
      customer: null,
      insNo: null
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'edit' || actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'add') {
          this.receiptHdrId = null;
          this.showCreateReceipt = true;
        }
        if (
          actionName === 'download' &&
          !this.showCreateReceipt &&
          !this.isAdvanceSearchEnable
        ) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload.pagination._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'receivables/getReceiptList',
            'reciept',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    enableAdvanceSearch() {
      this.isAdvanceSearchEnable = !this.isAdvanceSearchEnable;
    },
    rowSelected(item) {
      this.showCreateReceipt = true;
      this.receiptHdrId = item.receipt_hdr_id;
    },
    getReceiptList() {
      this.payload = {
        pagination: {
          _page: this.currentPage - 1,
          _limit: this.perPage
        }
      };
      if (this.searchParams) {
        this.payload.searchParams = this.searchParams;
      } else {
        this.payload = {
          pagination: {
            _page: this.currentPage - 1,
            _limit: this.perPage
          },
          searchParams: {
            document_num: this.receiptNum,
            instrument_start_date: commonHelper.formattedDate(
              this.rcptDateFromTo[0]
            ),
            instrument_end_date: commonHelper.formattedDate(
              this.rcptDateFromTo[1]
            ),
            module_id: this.selectedModule.id,
            with_unit: this.selectedModule.name === 'FMS' ? false : true,
            prj_id: this.projectId,
            customer_id: this.customer,
            instrument_number: this.insNo
          }
        };
      }
      this.loader = true;
      this.$store
        .dispatch('receivables/getReceiptList', this.payload)
        .then(response => {
          this.isAdvanceSearchEnable = false;
          this.loader = false;
          if (response.status === 200) {
            this.data = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(error => {
          this.loader = false;
          alert(error.message);
        });
    },
    clearSearch() {
      this.totalRows= null,
      this.rcptDateFromTo = [];
      this.selectedModule = {
        name: null,
        id: null
      };
      this.projectId = null;
      this.projectName = null;
      this.receiptNum = null;
      this.customer = null;
      this.insNo = null;
      this.data = [];
    },
    hideCreateReceiptComp() {
      this.showCreateReceipt = false;
    },
    hideModuleModal(flag) {
      this.showHideModule = flag;
    },
    selectedModuleData(module) {
      this.selectedModule.name = module.module_name;
      this.selectedModule.id = module.module_id;
      this.showHideModule = false;
    },
    mainSearch() {
      this.searchParams = null;
      if(this.selectedModule.name){
        this.getReceiptList();
      } else{
        alert("Please select Module")
      }
    },
    advanceSearch(payload) {
      this.searchParams = payload.searchParams;
      this.isAdvanceSearchEnable = false;
      this.getReceiptList();
    },
    chooseValueset(module) {
      if (module === 'FMS') {
        this.openValueSetModal(appStrings.VALUESETTYPE.FMS_PROJECT);
      } else {
        this.openValueSetModal(appStrings.VALUESETTYPE.LEASE_PROJECT_LIST);
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeout = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      this.projectName = item.value_code;
      this.projectId = item.value_set_dtl_id;
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
    this.$store.dispatch('receivables/saveAdvSearchFilters', null);
    // this.$store.state.receivables.receivables.adv_search = null;
  }
};
